import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { MemberProfileInfoState, MemberProfileInfoStore } from './member-profile-info.store';

@Injectable({ providedIn: 'root' })
export class MemberProfileInfoQuery extends Query<MemberProfileInfoState> {
  public memberProfileInfoState$ = this.select((state) => state.profileInfo);  
  constructor(protected store: MemberProfileInfoStore) {
    super(store);
  }  
}